import "core-js/modules/es.array.push.js";
import { ref, onMounted, nextTick } from "vue";
import { Search } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import useClipboard from "vue-clipboard3";

// 第一次使用的图片
// import firstImage from "@/assets/images/first-img.jpg";

export default {
  setup() {
    const inputValue = ref("");
    const currentUploadImg = ref(null);
    const showData = ref({});
    const frozenData = ref({});
    const loading = ref(false);
    const searchValueList = ref([]);
    const apiStatus = ref(0);
    const dialogVisible = ref(false);
    const editing = ref(false);
    const highlightPattern = ref({});
    let rectangles = [];
    const {
      toClipboard
    } = useClipboard();

    // 显示对话框
    const showDialog = () => {
      return new Promise(resolve => {
        dialogVisible.value = true;
        nextTick(() => {
          resolve();
        });
      });
    };

    // 编辑状态
    const toggleEdit = () => {
      if (editing.value) {
        // 保存接口
        loading.value = true;
        const {
          id
        } = showData.value;
        axios.post(`/artalk-api/edit-image/${id}`, showData.value).then(res => {
          ElMessage({
            showClose: true,
            message: "修改成功",
            type: "success"
          });
          editing.value = false;
        }).catch(err => {
          console.error("edit-image:", err);
          showErrorMessage(err);
        }).finally(() => {
          loading.value = false;
        });
      } else {
        // 冻结数据
        frozenData.value = JSON.parse(JSON.stringify(showData.value));
        // 进入编辑状态
        editing.value = true;
      }
      // 附加一个清空检索框的逻辑
      clearSearchBar();
    };
    const clearSearchBar = () => {
      inputValue.value = "";
    };

    // 接口错误提示
    const showErrorMessage = err => {
      const {
        status,
        statusText
      } = err.response;
      apiStatus.value = status;
      ElMessage({
        showClose: true,
        message: `错误代码: ${status}, 错误信息: ${statusText}`,
        type: "error"
      });
    };

    // 上传图片
    const uploadImage = formData => {
      loading.value = true;
      axios.post("/artalk-api/submit-image", formData).then(res => {
        const {
          status,
          data
        } = res;
        apiStatus.value = status;
        showData.value = formatData(data);

        // 渲染画框
        renderCoordinate(showData.value.position);
      }).catch(err => {
        console.error("submit-image:", err);
        showErrorMessage(err);
      }).finally(() => {
        loading.value = false;
      });
    };

    // 将图片渲染到id为canvas的元素上
    const renderImage = img => {
      const canvas = document.getElementById("canvas");
      const ctx = canvas.getContext("2d");
      const image = new Image();
      image.onload = function () {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);
      };
      image.src = img;
    };

    // 随机生成颜色
    const getRandomColor = () => {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    // canvas鼠标mousemove事件
    const onMouseMove = e => {
      const canvas = document.getElementById("canvas");
      const scaleX = canvas.width / canvas.offsetWidth;
      const scaleY = canvas.height / canvas.offsetHeight;
      const rect = canvas.getBoundingClientRect();
      const x = (e.clientX - rect.left) * scaleX;
      const y = (e.clientY - rect.top) * scaleY;
      let foundRectangle = null;

      // 检查每个矩形是否包含点击的坐标
      rectangles.forEach((rectangle, index) => {
        if (x >= rectangle.xL && x <= rectangle.xR && y >= rectangle.yL && y <= rectangle.yR) {
          foundRectangle = rectangle;
        }
      });
      if (foundRectangle) {
        highlightPattern.value = {
          name: foundRectangle.name,
          color: foundRectangle.color
        };
      } else {
        highlightPattern.value = {};
      }
    };

    // 将坐标绘制在canvas上
    const renderCoordinate = position => {
      rectangles = [];
      const canvas = document.getElementById("canvas");
      const ctx = canvas.getContext("2d");
      position.forEach(item => {
        const width = item.lowerRightCoor[0] - item.upperLeftCoor[0];
        const height = item.lowerRightCoor[1] - item.upperLeftCoor[1];
        const color = getRandomColor();
        ctx.beginPath();
        ctx.rect(item.upperLeftCoor[0], item.upperLeftCoor[1], width, height);
        ctx.strokeStyle = color;
        ctx.lineWidth = 4;
        ctx.stroke();
        rectangles.push({
          name: item.name,
          xL: item.upperLeftCoor[0],
          yL: item.upperLeftCoor[1],
          xR: item.lowerRightCoor[0],
          yR: item.lowerRightCoor[1],
          color
        });
      });
    };

    // 手动上传图片
    const handleUpload = e => {
      clearSearchBar();
      return new Promise(resolve => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        uploadImage(formData);

        // 创建 FileReader 对象，并读取文件内容
        const reader = new FileReader();
        reader.onload = function (event) {
          // event.target.result 包含了读取的结果
          renderImage(event.target.result);
          resolve();
        };
        reader.readAsDataURL(file);
      });
    };
    const formatData = rawData => {
      const formattedData = Object.entries(rawData).map(([id, value]) => {
        // 处理 color 属性
        let color;
        if (value.color.includes(":")) {
          const colorParts = value.color.split(":");
          const colorName = colorParts[0].trim();
          const colorDescribe = colorParts[1].trim();
          color = {
            name: colorName,
            describe: colorDescribe
          };
        } else {
          color = {
            name: value.color,
            describe: ""
          };
        }

        // 处理 outlook 属性
        const outlookParts = value.outlook.split(":");
        const outlookName = outlookParts[0].trim();
        const outlookDescribe = outlookParts[1].trim();

        // 处理 pattern 属性
        const pattern = value.pattern.map(patternString => {
          var _patternParts$;
          const patternParts = patternString.split(":");
          const patternName = patternParts[0].trim();
          const patternDescribe = ((_patternParts$ = patternParts[1]) === null || _patternParts$ === void 0 ? void 0 : _patternParts$.trim()) || "";
          return {
            name: patternName,
            describe: patternDescribe
          };
        });

        // 处理 position 属性
        const position = value.position.map(positionString => {
          const splitItem = positionString.split(" ");
          return {
            upperLeftCoor: [parseInt(splitItem[0]), parseInt(splitItem[1])],
            lowerRightCoor: [parseInt(splitItem[2]), parseInt(splitItem[3])],
            name: splitItem[4]
          };
        });
        return {
          id,
          ...value,
          color,
          outlook: {
            name: outlookName,
            describe: outlookDescribe
          },
          pattern,
          position
        };
      });

      // 因为只有一个对象，取数组中的第一个即可
      return formattedData[0];
    };

    // 获取默认的数据
    // const getImageExample = () => {
    //   loading.value = true;
    //   currentUploadImg.value = firstImage;
    //   axios
    //     .get("/artalk-api/get-image-info/example")
    //     .then((res) => {
    //       const { status, data } = res;
    //       apiStatus.value = status;
    //       showData.value = formatData(data);
    //     })
    //     .catch((err) => {
    //       console.error("example:", err);
    //       showErrorMessage(err);
    //     })
    //     .finally(() => {
    //       loading.value = false;
    //     });
    // };

    // 搜索
    const search = () => {
      if (inputValue.value) {
        axios.get(`/artalk-api/query/${inputValue.value}`).then(res => {
          if (!res.data.result.length) {
            ElMessage({
              showClose: true,
              message: "没有找到相关的结果",
              type: "warning"
            });
          }
          searchValueList.value = res.data.result;
        }).catch(err => {
          console.error("query:", err);
          const {
            status,
            statusText
          } = err.response;
          ElMessage({
            showClose: true,
            message: `错误代码: ${status}, 错误信息: ${statusText}`,
            type: "error"
          });
        });
      }
    };
    const refreshPage = () => {
      window.location.reload();
    };
    const copyText = async () => {
      if (editing.value) {
        // 恢复数据
        showData.value = JSON.parse(JSON.stringify(frozenData.value));
        // 取消编辑状态
        editing.value = false;
      } else {
        // 复制文本
        try {
          const text = JSON.stringify(showData.value);
          await toClipboard(text);
          ElMessage({
            showClose: true,
            message: "复制成功",
            type: "success"
          });
        } catch (e) {
          console.error(e);
          ElMessage({
            showClose: true,
            message: "复制失败",
            type: "error"
          });
        }
      }
    };
    onMounted(() => {
      // 第一次获取默认数据
      // getImageExample();
    });
    return {
      Search,
      inputValue,
      dialogVisible,
      showDialog,
      handleUpload,
      showData,
      loading,
      search,
      searchValueList,
      currentUploadImg,
      apiStatus,
      refreshPage,
      copyText,
      editing,
      toggleEdit,
      onMouseMove,
      highlightPattern
    };
  }
};