import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import _imports_0 from '@/assets/images/x.png';
const _hoisted_1 = ["element-loading-text"];
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "img-content"
};
const _hoisted_4 = {
  class: "right"
};
const _hoisted_5 = {
  key: 0,
  class: "search-value-list"
};
const _hoisted_6 = {
  class: "value-name"
};
const _hoisted_7 = {
  class: "value-desc"
};
const _hoisted_8 = ["title"];
const _hoisted_9 = {
  key: 0,
  class: "name"
};
const _hoisted_10 = {
  key: 1,
  class: "chinese"
};
const _hoisted_11 = {
  key: 1,
  class: "items-content"
};
const _hoisted_12 = {
  class: "item-layout"
};
const _hoisted_13 = {
  class: "item-label"
};
const _hoisted_14 = {
  class: "item-content"
};
const _hoisted_15 = {
  class: "item-detail"
};
const _hoisted_16 = {
  class: "item-detail_name"
};
const _hoisted_17 = {
  key: 1,
  class: "name-chinese"
};
const _hoisted_18 = {
  class: "item-detail_describe"
};
const _hoisted_19 = {
  class: "item-layout"
};
const _hoisted_20 = {
  class: "item-label"
};
const _hoisted_21 = {
  class: "item-content"
};
const _hoisted_22 = {
  class: "item-detail"
};
const _hoisted_23 = {
  class: "item-detail_name"
};
const _hoisted_24 = {
  key: 1,
  class: "name-chinese"
};
const _hoisted_25 = {
  class: "item-detail_describe"
};
const _hoisted_26 = {
  class: "item-layout"
};
const _hoisted_27 = {
  class: "item-label"
};
const _hoisted_28 = {
  class: "item-content"
};
const _hoisted_29 = {
  class: "item-detail_name"
};
const _hoisted_30 = {
  class: "item-detail_describe"
};
const _hoisted_31 = {
  key: 2,
  class: "item-layout tags-content"
};
const _hoisted_32 = {
  class: "item-label"
};
const _hoisted_33 = {
  class: "item-content tags-layout"
};
const _hoisted_34 = ["onClick"];
const _hoisted_35 = {
  key: 2,
  class: "parsing-failed"
};
const _hoisted_36 = {
  class: "failed-tip"
};
const _hoisted_37 = {
  class: "failed-desc"
};
const _hoisted_38 = {
  class: "buttons-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_dialog = _resolveComponent("el-dialog");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createBlock(_component_el_dialog, {
    class: "custom-dialog",
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => $setup.dialogVisible = $event),
    title: _ctx.$t('artalkText.title')
  }, {
    default: _withCtx(() => [_withDirectives((_openBlock(), _createElementBlock("section", {
      class: "custom-dialog_content",
      "element-loading-text": _ctx.$t('artalkText.imgProcessing')
    }, [_createElementVNode("input", {
      type: "file",
      accept: "image/jpeg",
      id: "file-input",
      ref: "fileInput",
      onChange: _cache[0] || (_cache[0] = (...args) => $setup.handleUpload && $setup.handleUpload(...args)),
      style: {
        "display": "none"
      }
    }, null, 544), _createElementVNode("section", _hoisted_2, [_createElementVNode("section", _hoisted_3, [_createElementVNode("canvas", {
      id: "canvas",
      onMousemove: _cache[1] || (_cache[1] = (...args) => $setup.onMouseMove && $setup.onMouseMove(...args))
    }, null, 32)]), _createElementVNode("section", {
      class: "upload-img",
      onClick: _cache[2] || (_cache[2] = $event => _ctx.$refs.fileInput.click())
    }, _toDisplayString(_ctx.$t("artalkText.uploadNewImg")), 1)]), _createElementVNode("section", _hoisted_4, [$setup.inputValue && $setup.searchValueList.length ? (_openBlock(), _createElementBlock("section", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.searchValueList, (item, index) => {
      return _openBlock(), _createElementBlock("section", {
        class: "value-item",
        key: `value_${index}`
      }, [_createElementVNode("section", _hoisted_6, _toDisplayString(item.class_name), 1), _createElementVNode("section", _hoisted_7, [_createElementVNode("p", {
        title: item.description
      }, _toDisplayString(item.description), 9, _hoisted_8)])]);
    }), 128))])) : _createCommentVNode("", true), _createVNode(_component_el_input, {
      modelValue: $setup.inputValue,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $setup.inputValue = $event),
      placeholder: _ctx.$t('artalkText.KBplaceholder'),
      "suffix-icon": $setup.Search,
      onInput: $setup.search
    }, null, 8, ["modelValue", "placeholder", "suffix-icon", "onInput"]), $setup.apiStatus === 200 ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [!$setup.loading ? (_openBlock(), _createElementBlock("section", _hoisted_9, [$setup.editing ? (_openBlock(), _createBlock(_component_el_input, {
      key: 0,
      modelValue: $setup.showData.name,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $setup.showData.name = $event)
    }, null, 8, ["modelValue"])) : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString($setup.showData.name), 1))])) : _createCommentVNode("", true), !$setup.loading ? (_openBlock(), _createElementBlock("section", _hoisted_11, [_createElementVNode("section", _hoisted_12, [_createElementVNode("section", _hoisted_13, _toDisplayString(_ctx.$t("artalkText.color")), 1), _createElementVNode("section", _hoisted_14, [_createElementVNode("section", _hoisted_15, [_createElementVNode("section", _hoisted_16, [$setup.editing ? (_openBlock(), _createBlock(_component_el_input, {
      key: 0,
      modelValue: $setup.showData.color.name,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $setup.showData.color.name = $event)
    }, null, 8, ["modelValue"])) : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString($setup.showData.color.name), 1))]), _createElementVNode("section", _hoisted_18, [$setup.editing ? (_openBlock(), _createBlock(_component_el_input, {
      key: 0,
      modelValue: $setup.showData.color.describe,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $setup.showData.color.describe = $event),
      autosize: "",
      type: "textarea"
    }, null, 8, ["modelValue"])) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [_createTextVNode(_toDisplayString($setup.showData.color.describe), 1)], 64))])])])]), _createElementVNode("section", _hoisted_19, [_createElementVNode("section", _hoisted_20, _toDisplayString(_ctx.$t("artalkText.outlook")), 1), _createElementVNode("section", _hoisted_21, [_createElementVNode("section", _hoisted_22, [_createElementVNode("section", _hoisted_23, [$setup.editing ? (_openBlock(), _createBlock(_component_el_input, {
      key: 0,
      modelValue: $setup.showData.outlook.name,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $setup.showData.outlook.name = $event)
    }, null, 8, ["modelValue"])) : (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString($setup.showData.outlook.name), 1))]), _createElementVNode("section", _hoisted_25, [$setup.editing ? (_openBlock(), _createBlock(_component_el_input, {
      key: 0,
      modelValue: $setup.showData.outlook.describe,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => $setup.showData.outlook.describe = $event),
      autosize: "",
      type: "textarea"
    }, null, 8, ["modelValue"])) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [_createTextVNode(_toDisplayString($setup.showData.outlook.describe), 1)], 64))])])])]), _createElementVNode("section", _hoisted_26, [_createElementVNode("section", _hoisted_27, _toDisplayString(_ctx.$t("artalkText.pattern")), 1), _createElementVNode("section", _hoisted_28, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.showData.pattern, (item, index) => {
      return _openBlock(), _createElementBlock("section", {
        class: "item-detail",
        key: `pattern_${index}`
      }, [_createElementVNode("section", _hoisted_29, [$setup.editing ? (_openBlock(), _createBlock(_component_el_input, {
        key: 0,
        modelValue: item.name,
        "onUpdate:modelValue": $event => item.name = $event
      }, null, 8, ["modelValue", "onUpdate:modelValue"])) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createElementVNode("span", {
        class: _normalizeClass(['name-chinese', $setup.highlightPattern.name === item.name ? 'highlight' : ''])
      }, _toDisplayString(item.name), 3), $setup.highlightPattern.name === item.name ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "pattern_current",
        style: _normalizeStyle({
          backgroundColor: $setup.highlightPattern.color
        })
      }, null, 4)) : _createCommentVNode("", true)], 64))]), _createElementVNode("section", _hoisted_30, [$setup.editing ? (_openBlock(), _createBlock(_component_el_input, {
        key: 0,
        modelValue: item.describe,
        "onUpdate:modelValue": $event => item.describe = $event,
        autosize: "",
        type: "textarea"
      }, null, 8, ["modelValue", "onUpdate:modelValue"])) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createTextVNode(_toDisplayString(item.describe), 1)], 64))])]);
    }), 128))])])])) : _createCommentVNode("", true), !$setup.loading ? (_openBlock(), _createElementBlock("section", _hoisted_31, [_createElementVNode("section", _hoisted_32, _toDisplayString(_ctx.$t("artalkText.label")), 1), _createElementVNode("section", _hoisted_33, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.showData.label, (item, index) => {
      return _openBlock(), _createElementBlock("p", {
        class: "custom-tag",
        key: `tag_${index}`
      }, [_createTextVNode(_toDisplayString(item) + " ", 1), $setup.editing ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _imports_0,
        class: "delete-tag",
        onClick: $event => $setup.showData.label.splice(index, 1)
      }, null, 8, _hoisted_34)) : _createCommentVNode("", true)]);
    }), 128))])])) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock("section", _hoisted_35, [_createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.$t("artalkText.unrecognition")), 1), _createVNode(_component_el_button, {
      color: "#B6986C",
      onClick: $setup.refreshPage
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("artalkText.unrecognition")), 1)]),
      _: 1
    }, 8, ["onClick"]), _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.$t("artalkText.tryHighDefinition")), 1)])), _createElementVNode("section", _hoisted_38, [_createVNode(_component_el_button, {
      class: "custom-button",
      color: "#271E05",
      onClick: $setup.toggleEdit
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($setup.editing ? _ctx.$t("artalkText.save") : _ctx.$t("artalkText.edit")), 1)]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      class: "custom-button",
      color: "#C95726",
      onClick: $setup.copyText
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($setup.editing ? _ctx.$t("artalkText.cancel") : _ctx.$t("artalkText.copy")), 1)]),
      _: 1
    }, 8, ["onClick"])])])], 8, _hoisted_1)), [[_directive_loading, $setup.loading]])]),
    _: 1
  }, 8, ["modelValue", "title"]);
}